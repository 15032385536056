import $ from 'jquery';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { readFlashMessage, getRandomInt, initToolTips, randomIndex, isMobile, addScrollListener } from './modules/functions';
//require('popper');


import swal from 'sweetalert2';

// any CSS you require will output into a single css file (app.css in this case)




$('document').ready(function(){
  init();
});


var target = window.location.hash;


function init()
{

  fitToWindow();
  readFlashMessage();
  addListeners();

  var animEmoji = function(){

    $('#screen-intro .emoji').removeClass('on');
    var emojis= ['earth','rocket'];
    var i = getRandomInt(emojis.length);

    var emo = '.emoji.-em-'+emojis[i];
    $(emo).stop().addClass('on');
  };
  setInterval(animEmoji,10000);
  animEmoji();

  animTeamSection();
  //animAchievments();

  if($('#contact_form_project').length>0){
    UpdateTextMission();
  }


}




function addListeners(){
  $( window ).resize(function() {
    fitToWindow();
  });
  animCards();
  initToolTips();
  addSkillsListener();
  addScrollListener();
  contactFormListener();
  addFaqListener();
}






var scrollNeededForEachCard = 100
function fitToWindow(){
  if($('#screen-intro').length>0)
  {
    $('#cards-content').css('width',($('#cards').width())+'px');
  }
}

function addFaqListener(){
  $('.toggleAnswer').click(function(){
    var qId = $(this).data('question-id');
    $('#'+qId).toggleClass('show');
  })
}

var cardsScroll = 0;
function animCards(){

  //initial animation
  var cardCount = $('.card').length;
  for(var i = 0; i<cardCount; i++){

    setTimeout(function(i){
      var klass = 'card-pos-'+(i+1);
      $('.card[data-pos="'+(i+1)+'"]').addClass(klass);
    },250*i, i)

  }
  setTimeout(function(){
    var defaultPost = 4;
    setCardPosToCenter(defaultPost);
  },250*i)

  //on click move
  $('.card').click(function(e){
    var pos = $(this).data('pos');

    setCardPosToCenter(pos);


  });
}



function setCardPosToCenter(pos){
  var cardCount = $('.card').length;
  for(var i = 0; i<=cardCount; i++){
    var klass = 'card-pos-'+(i+1);
    $('.card').removeClass(klass).removeClass('card-main');
  }

  var i = 1;
  for(var p = pos - 3; p<=pos+4; p++)
  {

    var cardpos = p;
    if(cardpos<=0){
      cardpos +=cardCount;
    }
    else if(cardpos>cardCount){
      cardpos -=cardCount
    }

    $('.card[data-pos="'+cardpos+'"]').addClass('card-pos-'+i);

    i++;
  }
}

function animTeamSection(){



  var images = $('#photos').data('bg');
  if(images){
    //preload image
    for(var i = 0; i < images.length; i++){
      var image = new Image();
      image.src=images[i];
    }
  }


  var animPhotos = function (){


    var images = $('#photos').data('bg');
    if(images){

      var pos = $('#photos').data('bg-pos');

      var i = getRandomInt(images.length);
      while(i==pos){
        i = getRandomInt(images.length);
      }
      $('#photos').css('background-image','');
      $('#photos').css('background-image','url("'+images[i]+'")');
      $('#photos').data('bg-pos',i);
    }

  };
  setInterval(animPhotos, 8000);

  var animMembers = function(){
    var members = $('#members a');
    var memberCount = members.length;
    var maxPos = 15;
    var firstPos = 1;
    for(var m = 0; m<memberCount;m++){
      var p = $(members[m]).data('pos');
      $(members[m]).removeClass('m-pos-'+p);

      p++;

      if(p>maxPos+1){
        p = firstPos;
        firstPos--;
      }

      $(members[m]).stop().addClass('m-pos-'+p).data('pos',p);

    }
  }

  setInterval(animMembers, 4000);
}

function animAchievments(){
  if(!isMobile()){
    $('#achievements').hover(function(){ $(this).addClass('stop'); }, function(){ $(this).removeClass('stop');});

    var move = function(){
      if(!$('#achievements').hasClass('stop') && !isMobile())
      {
        var achievements = $('.achievement');
        var count = achievements.length;
        for(var i = 0; i < count; i++)
        {
          var pos = parseInt($(achievements[i]).data('pos'));
          var newPos = (pos-1);
          var moveToLast = false;
          if(newPos<-1){
            newPos = count-2;
            moveToLast = true;
          }
          $(achievements[i]).removeClass('pos-'+pos).addClass('pos-'+newPos);
          $(achievements[i]).data('pos', newPos);
          if(moveToLast){
            //newPos = count-2;
            $('#achievements').append($(achievements[i]));
          }

        }

      }
    };

    setInterval(move, 4000);
    move();
  }
}


function addSkillsListener(){
  $('#skills h4').hover(function(){
    if(!isMobile()){
      var p = $(this).data('pos');
      if(p!=$('h4.on').data('pos')){
        var skillCount = $('#skills h4').length;

        $('h4.on').removeClass('on');
        $('.skill-desc.on').removeClass('on');

        $(this).addClass('on');
        $('.skill-desc[data-pos="'+p+'"]').addClass('on');

      }
    }
  }, function(){
    if(isMobile()){
      $('.skill-desc').addClass('on');
    }
  });

  var dspEmoji = function()
  {
    var maxState = 5;
    var p = $('h4.on').data('pos')
    var klass = '#skills .skill-'+p+'.emoji';


    // update class "on" with different state
    for (var i = maxState; i>1; i--){
      $(klass+'.on-'+(i-1)).addClass('on-'+i);
    }
    $(klass+'.on').addClass('on-1');



    var emoji = $(klass+':not(.on)');
    if(emoji.length>0){
      var i = getRandomInt(emoji.length)
      $(emoji[i]).stop().addClass('on').css('margin-top', getRandomInt(100)+'px');

    }

    //remove class "on"
    for (var i = 1; i<=maxState; i++)
    {
      $('#skills .on-'+maxState).removeClass('on').removeClass('on-'+i);
    }


  }

  setInterval(dspEmoji, 3000);
  dspEmoji();

}



var currentMission = -1;
var prevMission = null;
function UpdateTextMission(){
  var missions = [
    "J'ai un projet #TechForGood ❤️❤️!",
    "J'ai un projet dans l'économie circulaire",
    "J'ai un projet sur le handicap",
    "J'ai un projet social",
    "J'ai un projet autour des énergies propres",
    "Je défend la cause animal 🐾",
    "Je veux une application 📲",
    "Je veux consolider mon équipe avec un freelance",
    "Je veux optimiser mon référencement 🤓",
    "Je veux améliorer ma communication",
    "J'ai besoin d'un UX Designer",
    "J'ai besoin d'un CM",
    "J'ai besoin d'un développeur Web",
    "J'ai besoin de Relation Presse",
    "J'ai besoin de conseils",
    "Je lance le développement d'une application mobile 🚀🚀🚀",
    "J'ai besoin de communication presse",
    "J'ai besoin d'un Community Manager",
    "Je cherche un designer pour mon nouveau logo",
    "Un nouveau design pour mon site web",
    "Un nouveau design pour mon application",
    "J'ai besoin d'outils pour mon association",
    "Je veux faire bouger les choses ! 💪",
    "Je veux lever des fonds 💲",
    "J'ai besoin de financement pour mon association",
    "J'ai une idée de jeu éducatif !"
  ];

  var deltaDisplay = 1500;
  if(currentMission ==-1){
    // display first mission
    currentMission = randomIndex(missions);
    setMission(missions[currentMission])

    setTimeout(UpdateTextMission, deltaDisplay);
    return;
  }

  //gonna display next mission
  prevMission = currentMission;
  currentMission++;
  if(currentMission>=missions.length){
    currentMission=0;
  }

  var pos = 0;
  var t1 = missions[prevMission];
  var t2 = missions[currentMission];
  while (t1[pos]==t2[pos]){
    pos++;
  }

  // #1 erase txt
  var downStep = t1.length - pos;
  var upStep = t2.length - pos;
  var tsWriteout = 50;
  var tsWritein = 150;





  var downTo = 1;
  while(t1.length-downTo>=pos)
  {
    setTimeout(setMission, tsWriteout*downTo, t1.substr(0,t1.length-downTo) );
    downTo++;

  }

  // #2 write txt
  var step = 1;
  var upTo = pos+1;
  var timeWriteNextLetter = tsWriteout*downTo;
  while(upTo<=t2.length)
  {

    setTimeout(setMission, timeWriteNextLetter, t2.substr(0,upTo) );
    upTo++;
    step ++;
    var speedTs = tsWritein-step*5;
    if(speedTs<tsWriteout){
      speedTs = tsWriteout;
    }
    timeWriteNextLetter += speedTs ;
  }

  setTimeout(UpdateTextMission, timeWriteNextLetter + deltaDisplay);


}

function setMission(txt){
  $('#contact_form_project').attr('placeholder', txt);
}

function contactFormListener(){
  $('form[name="contact_form"]').submit(function(){
    $('#contact_form_strat1').val($('#contact_form_email').val());
    $('#contact_form_strat2').val($('#contact_form_firstname').val());
  });

}
